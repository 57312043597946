<template>
    <nav role="navigation" v-bind:class="{whiteTop: whiteTop, topMenuShow: topMenuShow}">
        <div class="desktop_menu">
          <router-link to="/">
            <img class="logo" :src="whiteTop ? '/images/logo-white.svg' : '/images/logo-black.svg' " alt="Coastal Luxe Logo">
          </router-link>
          <transition name="fade">
          <div id="menu" v-if="menuShow"> 
              <ul>
                <li v-for="item in menuItems" :key="item.label" >
                  <router-link v-if="item.type === 'regular'" :to="item.link" @click.native="linkClick" class="menuItem">
                    {{ item.label }}
                  </router-link>
                  <div class="menu-items" v-else>
                    <a :key="item.label" @click.prevent="expandMenu(item.label)" class="menuItem">
                      {{ item.label }}
                    </a>
                    <div class="subMenu"  v-show="item.expand">
                      <li v-for="subMenuItem in item.items" :key="subMenuItem.label">
                        <router-link v-if="!subMenuItem.external" :to="subMenuItem.link" @click.native="linkClick" class="subMenuItem">
                          {{ subMenuItem.label }}
                        </router-link>
                        <a v-if="subMenuItem.external" :href="subMenuItem.link" target="_blank" class="subMenuItem">
                          {{ subMenuItem.label }}
                        </a>
                      </li>
                    </div>
                  </div>
                </li>
              </ul>
              <FooterSocialMedia class="social-media"/>
          </div>
          </transition>
          <div id="menuToggle">
            <input 
              type="checkbox"
              v-model="menuShow"
              role="button"/>
            <img class="btn_menu" :class="{ 'btn_close': menuShow }" :src="getOpenCloseIcon()"/>
          </div>
        </div>
        <div class="mobile_menu">
          <router-link to="/">
            <span id="mobile_logo"/>
          </router-link>  
          <transition name="fade">
          <div id="menu" v-if="menuShow"> 
              <ul>
                <li v-for="item in menuItems" :key="item.label" >
                  <router-link v-if="item.type === 'regular'" :to="item.link" @click.native="linkClick" class="menuItem">
                    {{ item.label }}
                  </router-link>
                  <div class="menu-items" v-else>
                    <a :key="item.label" @click.prevent="expandMenu(item.label)" class="menuItem">
                      {{ item.label }}
                    </a>
                    <div class="subMenu"  v-show="item.expand">
                      <li v-for="subMenuItem in item.items" :key="subMenuItem.label">
                        <router-link v-if="!subMenuItem.external" :to="subMenuItem.link" @click.native="linkClick" class="subMenuItem">
                          {{ subMenuItem.label }}
                        </router-link>
                        <a  v-if="subMenuItem.external" :href="subMenuItem.link" target="_blank" class="subMenuItem">
                          {{ subMenuItem.label }}
                        </a>
                      </li>
                    </div>
                  </div>
                </li>
              </ul>
              <FooterSocialMedia class="social-media"/>
          </div>
          </transition>
          <div id="menuToggle">
            <input 
              type="checkbox"
              v-model="menuShow"
              role="button"/>
            <img class="btn_menu" :class="{ 'btn_close': menuShow }" :src="getOpenCloseIcon()"/>
          </div>
        </div>
    </nav>
</template>

<script>
import FooterSocialMedia from "../Footer/FooterSocialMedia";

export default {
    name: 'Menu',
    components: {FooterSocialMedia},
    data(){
        return{
          withBackground: false,
          menuItems: [
              {
                label: "Home",
                link: "/",
                type: 'regular'
              },
              {
                label: "Properties",
                type: 'expand',
                expand: false,
                items: [
                  {
                    label: "For Sale",
                    link: '/properties/forSale'
                  },
                  {
                    label: "For Lease",
                    link: '/properties/forLease'
                  },
                  {
                    label: "Sold",
                    link: '/properties/sold'
                  },
                  {
                    label: "Search",
                    link: 'https://idxhome.com/homesearch/89398',
                    external: true,
                  },
                  {
                    label: "Open Homes",
                    link: 'https://idxhome.com/openhomes/89398',
                    external: true,
                  },
                ]
              },
              {
                label: "Buildings",
                type: 'expand',
                expand: false,
                items: [
                  {
                    label: "Azzurra",
                    link: '/building/Azzurra'
                  },
                  {
                    label: "Regatta",
                    link: '/building/Regatta'
                  },
                  {
                    label: "Cove",
                    link: '/building/Cove'
                  },
                ],
              },
              {
                label: "Team",
                link: "/team",
                type: 'regular'
              },
              {
                label: "Contact",
                link: "/contact",
                type: 'regular'
              },
          ],
          menuShow: false,
          topMenuShow: false,
          whiteTop: true,
          window: {
            width: 0,
            height: 0
          },
      }
    },
    methods: {
      linkClick(){
        this.menuShow = false;
        this.expandMenu('');
      },
      handleScroll(){
        if (window.scrollY > 170){
          this.topMenuShow = true;
        } else if(window.scrollY < 20 && this.window.width > 1079){
          this.topMenuShow = false;
        }
      },
      getOpenCloseIcon(){
          if(this.menuShow){
            return '/images/hamburger-close.png'
          }
          else if(this.whiteTop || this.topMenuShow){
            return '/images/menu-white.png'
          } else{
            return '/images/menu.png'
          }
      },
      expandMenu(label){
        this.menuItems.map(
          (item) => {
            if(item.hasOwnProperty('expand')){
              // It was already expanded and need to close
              if(label === item.label){
                item.expand = !item.expand;
              } else {
                item.expand = false;
              }
            }
          }
        )
      },
      handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
        if (this.window.width < 1079){
          this.topMenuShow = true;
        }
        else if(window.scrollY < 20 && this.window.width > 1079){
          this.topMenuShow = false;
        }
      },
    },
    watch:{
      $route (to){
        this.menuShow = false;
        if(to.path.indexOf('team') >= 0 || to.path === '/' || to.path.indexOf('building') >= 0){
          this.whiteTop = true;
        } else {
          this.whiteTop = false;
        }
      }
    },
    created: function(){
      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener('resize', this.handleResize)
      this.handleResize();
    },
    destroyed(){
      window.removeEventListener('scroll', this.handleScroll);
      window.removeEventListener('resize', this.handleResize)
    },
  }
</script>

<style lang="scss" scoped>
nav {
  position: static;
  width: 100%;
  top: 0;
  z-index: 10;
  height: 180px;
  a {
    display: block;
    &:visited{
      color: #fff;
    }
    color: #fff;
    text-decoration: none;
  }
  .logo{
    position: absolute;
    padding-top: 45px;
    margin: 0 auto 0;
    width: 320px;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }
}

.btn_menu{
  margin-top: 22px;
  width: 26px;
  height: 13px;
  &.btn_close{
      width:18px;
      height: 18px;
      margin-right: 8px;
      margin-top: 26px;
  }
}

#menuToggle
{
  position: absolute;
  top: 33px;
  right: 40px;
  z-index: 20;
  -webkit-user-select: none;
  user-select: none;
  input {
    display: block;
    width: 40px;
    height: 40px;
    top: 10px;
    right: -5px;
    position: absolute;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    -webkit-touch-callout: none;
  }
}
.menu-items{
  a:hover{
    cursor: pointer;
    color: #f1f3f7;
  }
}
#menu
{
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
  display: block;
  background-color:rgba(0, 0, 0, 0.9);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  z-index: 10;
  ul{
    list-style-type: none;
    margin: 0 auto;
    margin-top: 40px;
    padding-inline-start: unset;
  }
  .menuItem{
    font-family: sweet-sans-pro,sans-serif;
  }
  li {
    color:#fff;
    padding: 0.75rem 0;
    font-size: 1.75rem;
    &:hover{
      opacity: 0.85;
    }
    a{
      font-size: 50px;
      font-weight: 500;
      margin: 0;
      &:hover{
        color: #f1f3f7;
      }
      &.subMenuItem{
        font-family: 'Manrope', sans-serif;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 3px;
      }
    }
  }
}
.social-media{
  margin-top: 2.5rem;
}
.mobile_menu{
  display: none;
}

.topMenuShow{
  .desktop_menu{
    display: none;
  }
  .mobile_menu{
    display: block;
    height: 60px;
    background: #000;
  }
  position: sticky;
  #mobile_logo{
    position: absolute;
    width: 200px;
    height: 30px;
    background-size: 200px 30px;
    background-image: url("/images/logo-mobile.svg");
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
  }
  #menuToggle
  {
    position: absolute;
    top: 0px;
    right: 40px;
    z-index: 10;
    -webkit-user-select: none;
    user-select: none;
    input {
      display: block;
      width: 40px;
      height: 40px;
      top: 10px;
      right: -5px;
      position: absolute;
      cursor: pointer;
      opacity: 0; /* hide this */
      z-index: 2; /* and place it over the hamburger */
      -webkit-touch-callout: none;
    }
  }
}
@media (max-width: $tablet) {
  .desktop_menu{
    display: none;
  }
  .mobile_menu{
    display: block;
  }
  nav{
    height: 60px;
  }
}
</style>
