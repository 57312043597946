import Vue from 'vue'
import App from './App.vue'
import store from './store/index'
import vmodal from 'vue-js-modal'

//router
import VueRouter from 'vue-router'
//Meta
import Meta from 'vue-meta';

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

//icons 
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInstagram, faLinkedin, faTwitterSquare, faFacebookSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { faPhoneAlt, faEnvelope, faTimes, faChevronDown, faChevronUp, faFile } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


import AOS from "aos";
import "aos/dist/aos.css";

import "./filters"
import VueLazyload from 'vue-lazyload'

Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.use(vmodal)


library.add(faInstagram, faLinkedin, faTwitterSquare, faFacebookSquare, faPhoneAlt, faEnvelope, faTimes, faYoutubeSquare, faChevronDown, faChevronUp, faFile)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(VueRouter);
Vue.use(Meta);
Vue.use(VueAxios, axios)

Vue.use(VueLazyload)

function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}

Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: 'images/loading.gif',
  attempt: 1
})

//Vue.component('GmapCluster', GmapCluster)

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: lazyLoad('Home') },
    { path: '/about', component: lazyLoad('About')},
    { path: '/team', component: lazyLoad('Team')},
    { path: '/thankyou', component: lazyLoad('ThankYou')},
    { path: '/contact', component: lazyLoad('Contact')},
    { path: '/properties/:category?', component: lazyLoad('Properties')},
    { path: '/property/:slug', component: lazyLoad('Property')},
    { path: '/building/:slug', component: lazyLoad('Building')},
    { path: "*", component: lazyLoad('PageNotFound') },
  ],
  scrollBehavior: function(to) {
    if (to.hash) {
        return { selector: to.hash }
    } else {
        return { x: 0, y: 0 }
    }
  },
})
new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    AOS.init()
  },
}).$mount('#app')
