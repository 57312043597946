<template>
  <div id="app">
    <transition 
      name="custom-classes-transition"
      leave-active-class="animated fadeOut">
      <div id="preloader" v-if="showLoader">
          <div id="loading-animation"></div>
      </div>
    </transition>
    <Header />
    <router-view></router-view>
    <Footer> </Footer>
    <FollowUs></FollowUs>
  </div>
</template>

<script>
import lazyLoadComponent from './utils/lazy-load-component';
import SkeletonBox from './components/SkeletonBox';
import Header from './components/Header'
import FollowUs from './components/FollowUs'

const defaultOptions = {
  loading: SkeletonBox,
  loadingData: {
    props: {
      width: `100%`,
      height: `20em`,
    },
  },
};

export default {
  name: 'app',
  components: {
    Header,
    FollowUs,
    Footer: lazyLoadComponent({
      ...defaultOptions,
      componentFactory: () => import(`./components/Footer.vue`),
    }),
  },
  data(){
    return {
       loaded: false,
    }
  },
  computed:{
    showLoader(){
      if (this.$route.path === '/' && !this.loaded){
        return true;
      } else {
        return false;
      }
    }
  },
  watch:{
    $route (){
      this.loaded = false;
      window.setTimeout(() => {
        this.loaded = true;
      }, 800)
    }
  },
  mounted:function(){
    window.setTimeout(() => {
      this.loaded = true;
    }, 800)
  }
}

import 'vueperslides/dist/vueperslides.css';
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;800&display=swap');
@import url('https://use.typekit.net/nea6amc.css');

html, body{
  font-family: sweet-sans-pro,sans-serif;
  position: relative;
  margin:0;
  padding:0;
  height: 100%;
  min-height: 100vh;
  width:100%;
  position: relative;
}

*{
  box-sizing: border-box;
}

p{
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
}

a{
  font-family: 'Manrope', sans-serif;
  text-decoration: none;
  &:visited{
    color: unset;
  }
}

h1,h2,h3,h4,h5,h6{
  font-weight: 500;
}

h2{
    font-size: 50px;
    margin: 10px 0;
    line-height: 45px;
}
h6{
    font-weight: 400;
    font-size: 22px;
    margin: 10px 0;
}
.page-title{
  text-align: center;
  margin: 4rem auto;
  h2{
    text-transform: unset;
  }
}

.menu-separator{
  text-align: center;
  margin: 0 auto;
  display: block;
  height: 1px;
  border: 0;
  width: 90%;
  border-top: 1px solid rgba(0,0,0,0.25);
}

button:focus{
  outline: none;
}

.vue-simple-spinner-text{
  font-family: 'Manrope', sans-serif;
  letter-spacing: 3px;
  font-size: 1rem !important;
  color: #000 !important;
}

.gm-style-iw{
  width: 200px;
  button{
    display: none !important;
  }
  &-c{
    padding: 0 !important;
  }
  &-d{
    max-width: unset !important;
    overflow: auto !important;
  }
}

.tag{
  position:absolute;
  left: 50%;
  transform: translateX(-50%);
  background: #000;
  top: -15.5px;
  color: #fff;
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
  font-size: 15px;
  padding: 5px 15px;
  z-index: 5;
}

.btn{
  display: inline-block;
  border: 1px solid #000;
  color: #000;
  padding: 8px 24px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 14px;
  font-weight: 700;
  margin: 1rem;
  &:hover{
      opacity: 0.75;
  }
}

.div-title{
  font-size: 50px;
  text-transform: unset;
}

.splatter-bottom, .splatter-top{
    background: white;
    img{
        display: block;
        width: 100%;
    }
}

.multiselect{
  min-width: 220px !important;
  margin-top: 10px;
  font-family: 'Manrope', sans-serif !important;
  color: #000 !important;
  font-weight: 500 !important;
  letter-spacing: 3px !important;
  font-size: 14px !important;
  &__single{
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 2px;
    text-transform: uppercase;
  }
}

.multiselect__tags{
    min-height: 40px;
    border-radius: 0 !important;
    border: 1px solid #000 !important;
}
.multiselect__select:before{
  border-color: #000 transparent transparent !important;
}

.multiselect__input, .multiselect__single{
  margin-top: unset;
  margin-bottom: unset;
  line-height: unset !important;
}

.multiselect__option{
    letter-spacing: 2px;
    text-transform: uppercase;
}

.multiselect__current, .multiselect__select{
    line-height: unset;
}
.multiselect__option--highlight{
  background: black !important;
}
.multiselect__option--selected.multiselect__option--highlight{
  color: #000 !important;
  background: #f2f5f9 !important;
}

#preloader
{
	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:999;
	background-color:#fff;
}

#loading-animation
{
	position:absolute;
	top:50%;
	left:50%;
	margin:-100px 0 0 -100px;
	width:200px;
	height:200px;
	background-image:url(/images/loading-animation.gif);
	background-position:center;
	background-repeat:no-repeat;
}

@media (max-width: $mobile) {
  .page-title{
    margin: 2rem auto;
  }
}
</style>