<template>
    <Menu></Menu>
</template>

<script>
import Menu from "./Header/Menu"
export default {
    name: "Header",
    components: {
        Menu,
    }
}
</script>