<template>
    <div class="socialLinks">
        <a v-for="item in socialMediaItems" :key="item.icon" v-bind:href="item.url" target="_blank">
            <font-awesome-icon :icon="['fab', `${item.icon}`]" class="socialIcon" v-bind:class="{blackText : blackText}"/>
        </a>
    </div>
</template>

<script>
export default {
    name: "FooterSocialMedia",
    props:['blackText'],
    data(){
        return{
            socialMediaItems: [
                {
                    icon: 'instagram',
                    url:"https://www.instagram.com/coastalluxeliving/",
                },
                {
                    icon: 'facebook-square',
                    url:"https://www.facebook.com/coastalluxeliving/",
                },
                {
                    icon: 'twitter-square',
                    url:"https://twitter.com/coastalluxe",
                },
                
            ]
        }
    }

}
</script>

<style lang="scss" scoped>
.socialLinks{
    display: flex;
    justify-content: center;
}
.socialIcon{
    margin: 0 0.75rem;
    font-size: 1.6rem;
    color: white;
    &:hover{
        opacity: 0.85;
    }
    &.blackText{
        color: #000;
    }
}
</style>