import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'es6-promise/auto'

Vue.use(Vuex)
Vue.use(VueAxios, axios)


const TWO_HOURS = 2 * 60 * 60 * 1000

const state = {
    loaded: false,
    expiryDate: new Date,
    properties: [],
    currentFilterOption:  {label: 'ALL PROPERTIES', value: 'all'},
}

// getters
const getters = {
    checkExpiryDate: (state) => {
        return ((new Date) - state.expiryDate) > TWO_HOURS;
    },
    getLoaded: (state) => {
        return state.loaded;
    },
    properties: (state) => {
        return state.properties;
    },
    currentFilterOption: (state) => {
        return state.currentFilterOption;
    },
}

// actions
const actions = {
    getProperties (context){
        context.commit('getProperties')
    },
    propertiesLoaded(context){
        context.commit('propertiesLoaded')
    },
    updateCurrentTime(context){
        context.commit('updateCurrentTime')
    },
    updateProperties(context){
        context.commit('getProperties');
        context.commit('updateCurrentTime')
    },
    updateFilterOption ({ commit }, newOption) {
        commit('setFilter', newOption)
    },
}

// mutations
const mutations = {
    getProperties (state){
        axios.get('https://strapi.uptowncreative.io/coastal-luxe-properties?_sort=published_at:DESC,Price:DESC,Status:ASC')
        .then((response) => {
            state.properties = response.data
        })
    },
    propertiesLoaded (state){
        state.loaded = true;
    },
    updateCurrentTime (state){
        state.expiryDate = new Date;
    },
    setFilter (state, newOption){
        state.currentFilterOption = newOption;
    }
}

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})